/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import { debounce } from "debounce"

import ScrollIcon from "../images/arrow_up.svg"
import "./scrollbutton.scss"

const ScrollButton = () => {
  const [showScrollUp, setShowScrollUp] = useState(false)

  const handleScroll = e => {
    setShowScrollUp(window.scrollY > 200)
  }

  useEffect(() => {
    window.addEventListener("scroll", debounce(handleScroll, 200))
  }, [showScrollUp])

  const handleClick = () => {
    window.scrollTo(0, 0)
  }

  return (
    <button
      disabled={!showScrollUp}
      className={`scrollbutton scrollbutton--${showScrollUp ? "show" : "hide"}`}
      onClick={handleClick}
    >
      <img src={ScrollIcon} alt="Arrow pointing up" />
    </button>
  )
}

export default ScrollButton
