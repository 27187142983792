import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import "./header.scss"

const HeaderItem = ({ to, children }) => (
  <Link className="header__list__item text__med" to={to}>
    {children}
  </Link>
)

HeaderItem.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
}

export default HeaderItem
