import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import "./header.scss"
import { RESUME_LINK } from "../utils/constants"
import menuIcon from "../images/menu-white-18dp.svg"
import menuIconOpen from "../images/menu_open-white-18dp.svg"
import githubIcon from "../images/github.png"
import stackoverflowIcon from "../images/SO.png"
import linkedInIcon from "../images/linkedin.png"
import HeaderItem from "./HeaderItem"

const Header = ({ siteTitle, path }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => setOpen(false), [path])
  const toggleOpen = () => setOpen(!open)

  const height = open ? "calc(100vh - 55px)" : "0px"
  const zIndex = open ? "100" : "-1"
  const imgSrc = open ? menuIconOpen : menuIcon

  return (
    <header id="header" className="header">
      <div className="header__container">
        <h1 style={{ margin: 0 }}>
          <Link to="/">{siteTitle}</Link>
        </h1>
      </div>
      <button className="header__menutoggle" onClick={toggleOpen}>
        <img src={imgSrc} alt="Open menu" />
      </button>
      <div className="header__list" style={{ height, zIndex }}>
        <HeaderItem to="/articles">All Articles</HeaderItem>
        <HeaderItem to="/portfolio">Portfolio</HeaderItem>
        <a
          className="header__list__item text__med"
          href={RESUME_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          Resume
        </a>
        <div className="header__list__icons">
          <a
            href="https://github.com/erik-niehaus"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={githubIcon}
              alt="Visit Github"
              style={{ height: "45px" }}
            />
          </a>
          <a
            href="https://stackoverflow.com/users/4396787/erik-sn?tab=profile"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={stackoverflowIcon} alt="Visit stackoverflow" />
          </a>
          <a
            href="https://www.linkedin.com/in/erik-niehaus-35a443219/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedInIcon} alt="Visit Linkedin" />
          </a>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
