import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import "./header.scss"
import { RESUME_LINK } from "../utils/constants"
import githubIcon from "../images/github.png"
import stackoverflowIcon from "../images/SO.png"
import linkedInIcon from "../images/linkedin.png"
import HeaderItem from "./HeaderItem"

const Header = ({ siteTitle }) => {
  return (
    <header id="header-desktop" className="header">
      <div className="header__container">
        <h3 style={{ margin: 0 }}>
          <Link to="/">{siteTitle}</Link>
        </h3>
      </div>
      <div id="desktop-header-list">
        <HeaderItem to="/articles">All Articles</HeaderItem>
        <HeaderItem to="/portfolio">Portfolio</HeaderItem>
        <a
          className="header__list__item text__med"
          href={RESUME_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          Resume
        </a>
        <div id="desktop-header-icons">
          <a href="https://github.com/erik-niehaus">
            <img
              src={githubIcon}
              id="github-header-icon"
              alt="Visit Github"
              style={{ height: "36px", width: "36px" }}
              target="_blank"
              rel="noopener noreferrer"
            />
          </a>
          <a href="https://stackoverflow.com/users/4396787/erik-sn?tab=profile">
            <img
              src={stackoverflowIcon}
              alt="Visit stackoverflow"
              target="_blank"
              rel="noopener noreferrer"
            />
          </a>
          <a
            href="https://www.linkedin.com/in/erik-niehaus-35a443219/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedInIcon} alt="Visit Linkedin" />
          </a>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
